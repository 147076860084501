<template>
  <div class="main-container">
    <p class="rem30 c-333333 apply-liveAddress fNormal">家庭住址</p>
    <div class="choose-area" :class="fieldValue ? 'choose-area' : 'choose-area1'">
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        :label="fieldValue ? '' : '省、市、区'"
        placeholder="请选择"
        @click="show = true"
        rows="1"
        autosize
        type="textarea"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          @close="show = false"
          @finish="onFinish"
          :field-names="fieldNames"
        />
      </van-popup>
    </div>
    <p class="apply-title">街道详细信息</p>
    <van-field
      label-width="0"
      v-model="formData.liveAddress"
      rows="1"
      autosize
      type="textarea"
      placeholder="请输入家庭详细地址"
    />
    <p class="rem30 c-333333 apply-link fNormal">紧急联系人</p>
    <p class="apply-title">联系人姓名</p>
    <van-field label-width="0" v-model="formData.contactName" placeholder="请填写联系人姓名">
      <!-- <template #right-icon>
        <img :src="linklistPng" alt="" class="img26" @click="getLinkName" />
      </template> -->
    </van-field>
    <p class="apply-title">手机号码</p>
    <van-field
      label-width="0"
      v-model="formData.maskPhone"
      type="digit"
      maxlength="11"
      placeholder="请填写联系人电话"
    />
    <div class="apply-relation">
      <van-cell is-link title="联系人关系" @click="loanPeriodShow = true" :value="relation" />
      <van-action-sheet
        v-model="loanPeriodShow"
        :actions="loanPeriodList"
        @select="loanPeriodSelect"
      />
    </div>
    <p class="apply-title">邮箱</p>
    <van-field
      label-width="0"
      v-model="formData.emailAddr"
      placeholder="请填写录入邮箱（非必填）"
    />

    <div class="mt40">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        @click="nextStep"
        :disabled="
          !formData.liveAddress ||
          !formData.maskPhone ||
          !formData.relationship ||
          !formData.contactName ||
          !fieldValue
        "
        >下一步</van-button
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, ActionSheet, Cell, Popup, Button, Cascader, Icon } from 'vant'

import {
  submitUserInfoApi,
  getUserInfoApi,
  queryRelationApi,
  getAreaTreeApi,
} from '@/apis/borrow/index'
import { mapState } from 'vuex'
import { Encrypt, Decrypt } from '@/utils/crypto'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(ActionSheet)
Vue.use(Cell)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Cascader)
Vue.use(Icon)

export default {
  data() {
    return {
      show: false,
      fieldValue: '',
      cascaderValue: '',
      // 联系人关系
      loanPeriod: '请选择',
      loanPeriodShow: false,
      loanPeriodList: [],
      linklistPng: require('@/assets/images/borrow/linklist.png'),
      options: [],
      relation: '',
      // decryptPhone: '178****8282',
      formData: {
        liveAddress: '', //家庭地址
        maskPhone: '', // 紧急联系人电话
        contactName: '', // 紧急联系人姓名
        liveProvinceName: '', // 省
        liveCityName: '', // 市
        liveDistrictName: '', // 区
        relationship: '', // 关系
        emailAddr: '',
      },
      fieldNames: {
        text: 'name',
        value: 'areaCode',
        children: 'childerList',
      },
    }
  },
  created() {
    this.getCityData()
  },
  computed: {
    ...mapState({
      productCode: (state) => state.common.productCode,
      loanNo: (state) => state.borrow.loanNo,
    }),
  },
  methods: {
    getCityData() {
      getAreaTreeApi({}).then((res) => {
        if (res.code == 200) {
          this.options = res.data[0].childerList || []
          this.getUserInfo()
        }
      })
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfoApi({
        productCode: this.productCode,
      }).then((res) => {
        if (res.code === 200) {
          this.formData = res.data
          if (res.data.userContactInfoList && res.data.userContactInfoList.length > 0) {
            this.$set(this.formData, 'contactName', res.data.userContactInfoList[0].contactName)
            this.$set(
              this.formData,
              'maskPhone',
              Decrypt(res.data.userContactInfoList[0].maskPhone)
            )
            this.formData.relationship = res.data.userContactInfoList[0].relationship
          }
          this.queryRelation()
          this.showArea(
            this.formData.liveProvinceName,
            this.formData.liveCityName,
            this.formData.liveDistrictName,
            this.options
          )
        }
      })
    },
    // 获取联系人关系
    queryRelation() {
      queryRelationApi().then((res) => {
        if (res.code === 200) {
          this.loanPeriodList = res.data
          this.loanPeriodList.forEach((item) => {
            item.name = item.comCodeDesc
            if (this.formData.relationship == item.comCode) {
              this.relation = item.comCodeDesc
            }
          })
        }
      })
    },
    // 反显省市区
    showArea(province, city, area, arr) {
      let text = ''
      arr.forEach((item) => {
        if (item.areaCode == province) {
          text = item.name
          item.childerList.forEach((val) => {
            if (val.areaCode == city) {
              text += '/' + val.name
              val.childerList.forEach((v) => {
                if (v.areaCode == area) {
                  text += '/' + v.name
                }
              })
            }
          })
        }
      })
      this.fieldValue = text
    },
    onFinish({ selectedOptions }) {
      this.show = false
      this.fieldValue = selectedOptions.map((option) => option.name).join('/')
      this.formData.liveProvinceName = selectedOptions[0].areaCode
      this.formData.liveCityName = selectedOptions[1].areaCode
      this.formData.liveDistrictName = selectedOptions[2].areaCode
    },
    loanPeriodSelect(item) {
      this.relation = item.name
      this.formData.relationship = item.comCode
      this.loanPeriodShow = false
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    nextStep() {
      if (!this.$validate.isPhone(this.formData.maskPhone)) {
        this.$common.toast('请输入正确的手机号')
        return
      }
      if (this.formData.emailAddr && !this.$validate.isEmail(this.formData.emailAddr)) {
        this.$common.toast('请输入正确的邮箱')
        return
      }
      this.showloading()
      let params = {
        domicileProvince: this.formData.liveProvinceName,
        domicileCity: this.formData.liveCityName,
        domicileArea: this.formData.liveDistrictName,
        domicileAddr: this.formData.liveAddress,
        billNo: this.loanNo,
        contactsName: this.formData.contactName,
        relation: this.formData.relationship,
        contactsMobile: Encrypt(this.formData.maskPhone),
        email: this.formData.emailAddr,
        latitude: '',
        longitude: '',
        // 39.75 116.13
      }
      submitUserInfoApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.hideloading()
            this.$router.push({
              path: '/upload-certificate',
              // path: '/upload-invoice',
            })
          } else {
            this.hideloading()
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding: 0.12rem 0;
  border-bottom: 1px solid #e7e7e7;
  // margin-top: 0.46rem;
}
.apply-liveAddress {
  margin-bottom: 0.68rem;
}
.apply-link {
  margin: 0.56rem 0 0.68rem;
}
/deep/ .van-field__clear,
/deep/ .van-field__right-icon {
  display: flex;
  align-self: center;
}
.apply-title {
  color: #585c68;
  font-size: 0.26rem;
  margin-top: 0.38rem;
}
.apply-relation {
  .van-cell {
    padding: 0.4rem 0 0.16rem;
  }
}
.choose-area {
  /deep/ .van-field__control {
    text-align: left;
  }
}
.choose-area1 {
  /deep/ .van-field__control {
    text-align: right;
  }
}
/deep/ .van-tabs__line {
  background-color: #4d7bfe;
}
/deep/ .van-cascader__option--selected {
  color: #4d7bfe;
}
</style>
